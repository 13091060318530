/*
 * 업무구분: 영업
 * 화 면 명: MSPFS310M
 * 화면설명: 환매신청2020
 * 작 성 일: 2023.05.29
 * 작 성 자: 송진의 
 */
<template>
  <ur-page-container
    class="fts"
    :show-title="false"
    :title="pHeaderObj.title"
    :topButton="true"
  >
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40 w100p">
                <div class="wrap-input row">
                  <label class="emphasis">계좌번호</label>
                  <mo-decimal-field  
	                  numeric
                    ref="ed_accn_no"
                    class="w130 input-account"                    
                    :class="lv_ed_accn_no.error ? 'error' : ''"
                    v-model="lv_ed_accn_no.value"                    
                    @keyup="fn_SearchValid($event)"
                    @paste="fn_SearchValid($event, 'P')"
                    mask="#######-###"
                  />
                  <mo-dropdown
                    ref="ed_acct_gb"
                    class="w370"
                    :items="lv_acct_list"
                    v-model="lv_ed_acct_gb.value"
                    placeholder=" "
                    @input="fn_AcctNoCall()"
                    :disabled="lv_ed_acct_gb.disabled"
                  />
                </div>
                <div class="wrap-input row flex-unset">
                  <label> 저축유형 </label>
                  <mo-dropdown
                    :items="lv_commCode.code231"                    
                    v-model="lv_cbo_save_tp.value"
                    placeholder=" "
                    disabled
                  />
                </div>
              <div class="right w100p">
                <div class="wrap-button row">
                  <mo-button class="btn-clear"
                    v-if="!lv_btn_cfm" 
                    @click="fn_ConfirmPrint"
                    disabled
                  >
                    확인서
                  </mo-button>
                  <!-- <mo-button class="btn-clear" @click="fn_PrintTest">
                    프린트
                  </mo-button> -->
                  <mo-button class="btn-clear" @click="fn_Init()">
                    초기화
                  </mo-button>
                  <mo-button
                    primary
                    class="btn-inquiry"
                    @click="fn_SearchList()"
                    :disabled="lv_btn_search"
                  >
                    조회
                  </mo-button>
                </div>
              </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40 w100">
                <div class="wrap-input row">
                  <label class="emphasis"> 유선구분 </label>
                  <mo-radio v-model="lv_rdo_ars_gb.value" class="flex-unset mw-auto mr14" value="N"> 일반 </mo-radio>
                  <mo-radio v-model="lv_rdo_ars_gb.value" class="flex-unset mw-auto"      value="Y" disabled> 유선 </mo-radio>
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row">
                  <label class="emphasis"> 비밀번호 </label>
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    ref="ed_secure_password"
                    class="w80"
                    :class="lv_ed_secure_password.error ? 'error' : ''"
                    v-model="lv_ed_secure_password.value"
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt"         
                    />
                  <mo-text-field
                    v-else
                    ref="ed_secure_password"
                    class="w80"
                    :class="lv_ed_secure_password.error ? 'error' : ''"
                    v-model="lv_ed_secure_password.value"
                    type="password"
                    maxlength="4"
                    @input="fn_ChkPwd()"                    
                  />
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 대리인여부 </label>
                  <mo-radio-wrapper
                    :class="lv_rdo_agnt_chk_yn.error ? 'error' : ''"                
                    :items="itemsAgent" 
                    v-model="lv_rdo_agnt_chk_yn.value" 
                    class="row"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 연금환매구분 </label>
                  <mo-radio-wrapper
                    :class="lv_rdo_pension_trans_yn.error ? 'error' : ''"
                    :items="lv_rdo_pension_trans_yn_items"
                    v-model="lv_rdo_pension_trans_yn.value"
                    :disabled="lv_rdo_pension_trans_yn.disabled"
                    class="row"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container direction="column" alignV="start">
        <div class="row gap20 align-start w100p">
          <div class="left w50p column">
            <div class="wrap-table-title">
              <h2 class="table-title">환매신청사항</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type w50p col2030">
                <!-- <colgroup>
                  <col width="37%">
                  <col width="63%">
                </colgroup> -->
                <tbody>
                  <tr>
                    <th>
                      <span class="emphasis"> 환매구분 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown
                          :items="lv_cbo_redem_gb_items"
                          :class="lv_cbo_redem_gb.error ? 'error' : ''"                          
                          v-model="lv_cbo_redem_gb.value"
                          :disabled="lv_cbo_redem_gb.disabled"
                          @input="fn_ChangeCboRedemGb()"
                          placeholder="선택하세요"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="emphasis"> 환매신청금액(좌수) </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-decimal-field
                          numeric
                          ref="ed_redem_req_qty"
                          mask="number"
                          class="input-long"
                          :class="lv_ed_redem_req_qty.error ? 'error' : ''"
                          v-model="lv_ed_redem_req_qty.value"
                          :disabled="lv_ed_redem_req_qty.disabled"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 환매예약일자 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-date-picker
                          class="input-long"
                          :class="lv_cal_std_dt.error ? 'error' : ''"
                          v-model="lv_cal_std_dt.value"                                                    
                          :bottom="false"
                          disabled
                        />
                        <mo-radio-wrapper
                          :class="lv_rdo_fi_gb.error ? 'error' : ''"
                          :items="lv_rdo_fi_gb_items"
                          v-model="lv_rdo_fi_gb.value"
                          :disabled="lv_rdo_fi_gb.disabled"
                          @input="fn_ChangeRdoFiGb()"                          
                          class="row"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 출금지급방법 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-radio-wrapper
                          :class="lv_rdo_paym_repay_mth.error ? 'error' : ''"
                          :items="lv_commCode.code342"                          
                          v-model="lv_rdo_paym_repay_mth.value"
                          @input="fn_ChangeRdoPaymRepayMth()"
                          :disabled="lv_rdo_paym_repay_mth.disabled"
                          class="row"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 약정계좌번호 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown
                          :class="lv_cbo_btrans_acct_no.error ? 'error' : ''"
                          :items="lv_cbo_btrans_acct_no_items"
                          v-model="lv_cbo_btrans_acct_no.value"
                          :disabled="lv_cbo_btrans_acct_no.disabled"
                          placeholder=" "
                          @input="fn_ChangeCboBtransAcctNo()"                                                    
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 예수금(브릿지)계좌 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field
                          class="input-long"
                          :class="lv_ed_depo_acct_no.error ? 'error' : ''"
                          v-model="lv_ed_depo_acct_no.value"
                          :disabled="lv_ed_depo_acct_no.disabled"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> MMF매입계좌번호 </span>
                    </th>
                    <td>
                      <div class="picker row">
                        <mo-dropdown
                          :class="lv_cbo_buy_plan_acct_no.error ? 'error' : ''"
                          :items="lv_cbo_buy_plan_acct_no_items"
                          v-model="lv_cbo_buy_plan_acct_no.value"
                          @input="fn_ChangeBuyPlanAcctNo()"
                          placeholder=" "
                          :disabled="lv_cbo_buy_plan_acct_no.disabled"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 계좌해지여부 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-radio-wrapper
                          :class="lv_rdo_accn_rsc_apl_yn.error ? 'error' : ''"
                          :items="lv_rdo_yn_items"
                          v-model="lv_rdo_accn_rsc_apl_yn.value"
                          :disabled="lv_rdo_accn_rsc_apl_yn.disabled"
                          class="row"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right w50p column">
            <div class="wrap-table-title">
              <h2 class="table-title">정보조회</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type w50p col2030">                
                <tbody>
                  <tr>
                    <th>
                      <span> 전액 출금가능금액 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field
                          class="input-long"
                          mask="number"
                          v-model="lv_ed_paym_possamt.value"
                          :disabled="lv_ed_paym_possamt.disabled"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 환매가능금액/좌수 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field
                          ref="ed_paym_possqty"
                          class="input-long"
                          mask="number"
                          v-model="lv_ed_paym_possqty.value"
                          :disabled="lv_ed_paym_possqty.disabled"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 환매가능수수료 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field
                          class="input-long"
                          mask="number"
                          v-model="lv_ed_fee.value"
                          :disabled="lv_ed_fee.disabled"
                          
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="!lv_ed_recv_fee.disabled">
                    <th>
                      <span> 추징유보세 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field
                          class="input-long aR"
                          mask="number"
                          v-model="lv_ed_recv_fee.value"
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="!lv_ed_outcome_rewd.disabled">
                    <th>
                      <span> 성과보수 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field
                          class="input-long aR"
                          mask="number"
                          v-model="lv_ed_outcome_rewd.value"
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="wrap-table mt10">
              <table class="table col-type w50p col2030">                
                <tbody>
                  <tr>
                    <th>
                      <span> CMS신청 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-radio-wrapper
                          :items="lv_rdo_yn_items"
                          v-model="lv_rdo_cms_yn.value"
                          :disabled="lv_rdo_cms_yn.disabled"
                          class="row"
                        />
                      </div>
                    </td>
                    <th>
                      <span> 자동대체<br />신청 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-radio-wrapper
                          :items="lv_rdo_yn_items"
                          v-model="lv_rdo_autoalt_yn.value"
                          :disabled="lv_rdo_autoalt_yn.disabled"
                          class="row"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 환매체결일 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-date-picker
                          class="input-long"
                          v-model="lv_cal_apply_dt.value"
                          :disabled="lv_cal_apply_dt.disabled"
                          :bottom="false"
                        />
                      </div>
                    </td>
                    <th>
                      <span> 만기일 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-date-picker
                          class="input-long"
                          v-model="lv_cal_exp_dt.value"
                          :disabled="lv_cal_exp_dt.disabled"
                          :bottom="false"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 환매결제일 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-date-picker
                          class="input-long"
                          v-model="lv_cal_setl_plan_dt.value"
                          :disabled="lv_cal_setl_plan_dt.disabled"
                          :bottom="false"
                        />
                      </div>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-if="!lv_tax.disabled">
        <div class="wrap-table mt20">
          <table class="table col-type w50p col2030">            
            <tbody>
              <tr>
                <th>
                  <span> 기타소득세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_tax.inc"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 해지소득세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"
                      v-model="lv_tax.levy_inc"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 기타주민세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"
                      v-model="lv_tax.resi"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 해지주민세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"
                      v-model="lv_tax.levy_resi"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 합산 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"
                      v-model="lv_tax.total"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 합산 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"
                      v-model="lv_tax.levy_total"
                      disabled
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="wrap-desc none-border mt16">
          <p class="exp-txt">
            주의 : 상기 세금은 현재 기준으로 가계산한 세금입니다. 실제 결제 시
            금액이 변동됩니다.
          </p>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-if="!lv_pay.disabled">
        <div class="wrap-table mt20 h-scroll">
          <table class="table col-type">            
            <tbody>
              <tr>
                <td>
                  <span> 1년차 </span>
                </td>                
                <th class="th-border-left">
                  <span> 납임금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.sum_year_1"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 추징소득세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.lev_incm_tax_year_1"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 추징주민세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.lev_reci_tax_year_1"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span> 2년차 </span>
                </td>                
                <th class="th-border-left">
                  <span> 납임금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.sum_year_2"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 추징소득세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.lev_incm_tax_year_2"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 추징주민세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.lev_reci_tax_year_2"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span> 3년차 </span>
                </td>                
                <th class="th-border-left">
                  <span> 납임금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.sum_year_3"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 추징소득세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.lev_incm_tax_year_3"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span> 추징주민세 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"                      
                      v-model="lv_pay.lev_reci_tax_year_3"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <th>
                  <span> 예상추징세액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="number"
                      v-model="lv_pay.lev_amt"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
        <div class="wrap-desc none-border mt16">
          <p class="exp-txt">
            주의 : 상기 세금은 현재 기준으로 가계산한 세금입니다. 실제 결제 시
            금액이 변동됩니다.
          </p>
        </div>
      </ur-box-container>

       <ur-box-container  v-if='!ds_rp2020_1.disabled'>
        <div class="wrap-table mt20 h-scroll">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"> </th>
                <th class="w100"> 거래일자 </th>
                <th class="w100"> 거래번호 </th>
                <th class="w100"> 매입구분 </th>
                <th class="w120"> 입금액 </th>
                <th class="w120"> 잔고좌수 </th>
                <th class="w120"> 매입기준가 </th>
                <th class="w120"> 과표기준가 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in ds_rp2020_1.value" :key="idx" :class="{'checked': ds_rp2020_1.selIdx == String(idx+1)}" @click="ds_rp2020_1.selIdx = String(idx+1)">
                <td>
                  <mo-radio v-model="ds_rp2020_1.selIdx" :value="`${String(idx+1)}`"></mo-radio>
                </td>
                <td>
                  {{row.buy_stl_ymd}}
                </td>
                <td>
                  {{row.buy_stl_dl_no}}
                </td>
                <td>
                  {{row.recp_gb}}
                </td>
                <td>
                  {{row.buy_rctm_amt}}
                </td>
                <td>
                  {{row.bfcer_blc}}
                </td>
                <td>
                  {{row.bfcer_buy_fnprc}}
                </td>
                <td>
                  {{row.taxt_stdas_fnprc}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container v-if='!ds_rp2510_2.disabled'>
        <div class="wrap-table mt20 h-scroll">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w100"> 거래일자 </th>
                <th class="w100"> 거래번호 </th>
                <th class="w120"> 원금 </th>
                <th class="w120"> 평가금액 </th>
                <th class="w120"> 잔고좌수 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in ds_rp2510_2.value" :key="idx">
                <td>
                  {{row.orign_trd_dt}}
                </td>
                <td>
                  {{row.orign_trd_no}}
                </td>
                <td>
                  {{row.orign_amt}}
                </td>
                <td>
                  {{row.orign_eval_amt}}
                </td>
                <td>
                  {{row.orign_remn_qty}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_OnConfirm()" :disabled="lv_btn_confirm">확인</mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

     <!-- popup313 -->
    <msp-fs-313p
      ref="popup313"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup313Obj"
    ></msp-fs-313p>

    <!-- //.fts-main -->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from "~/src/ui/fs/comm/FSCommUtil";
import FSServiceUtil from "~/src/ui/fs/comm/FSServiceUtil";
import FSCodeUtil from "~/src/ui/fs/comm/FSCodeUtil";
import FSInfoUtil from "~/src/ui/fs/comm/FSInfoUtil";
import FSMessageUtil from "~/src/ui/fs/comm/FSMessageUtil";

import FSHeader from "@/ui/fs/comm/FSHeader";
import FSAlertPopup from "@/ui/fs/comm/FSAlertPopup"; // Alert 팝업 (공통)
import FSCustInfo from "~/src/ui/fs/comm/FSCustInfo"; // 고객정보 (공통)
import MSPFS313P from "~/src/ui/fs/MSPFS313P"; // 대리인정보 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS310M",
  screenId: "MSPFS310M",
  components: {
    "fs-header": FSHeader,
    "fs-alert-popup": FSAlertPopup,
    "fs-cust-info": FSCustInfo,
    "msp-fs-313p": MSPFS313P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode();

    // let lv_Vm = this;
    // lv_Vm.$bizUtil.fsUtils
    //   .selBasInfo(lv_Vm, false)
    //   .then(function (response) {
    //     lv_Vm.lv_basInfo = lv_Vm.getStore("fsStore").getters.getBasInfo.data;
    //   })
    //   .catch(function (error) {
    //     window.vue.error(error);
    //   });
  },
  mounted() {
    this.fn_Init();
    this.$bizUtil.insSrnLog(this.$options.screenId);
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false,            // 보안키패드 초기화

      // 공통 객체
      pHeaderObj: {
        title: "환매신청",
        step: "",
      },
      
      lv_commCode: {},      

      // 팝업 객체      
      pCustInfoObj: {}, // 고객정보
      pPopup313Obj: {}, // 대리인정보
      pAlertPopupObj: {},
      

      ds_pass: {},
      ds_rpus: {}, // 2510 (인출가능금액 정보)
      ds_info: {}, // 2020 (매입내역 정보)
      ds_rpus_list: [], // 2510 list (인출가능금액 목록)
      ds_info_list: [], // 2020 list (매입내역 목록)
      ds_search: {}, // 조회 데이터

      // 검색조건
      lv_accn_no: {},
      lv_ed_accn_no: {value: ''},  // 계좌번호      
      lv_ed_acct_gb: {},  // 계좌종류
      lv_rdo_ars_gb: {},  // 유선구분
      lv_rdo_agnt_chk_yn: {},       // 대리인여부
      lv_rdo_pension_trans_yn: {},  // 연금환매구분

      lv_ed_secure_password: {},    // 비밀번호

      // 결과
      // 환매신청사항
      lv_cbo_redem_gb: {},       // 환매구분
      lv_cbo_redem_gb_items: [], // 환매구분 list , lv_commCode.code322
      lv_ed_redem_req_qty: {},  // 환매신청금액(좌수)
      lv_cal_std_dt: {},         // 환매예약일자
      lv_rdo_fi_gb: {},          // fi구분      
      lv_rdo_paym_repay_mth: {}, // 출금지급방법
      lv_cbo_btrans_acct_no: {}, // 약정계좌번호
      lv_cbo_btrans_acct_no_items: [], // 약정계좌번호 list
      lv_ed_depo_acct_no: {},   // 예수금(브릿지)계좌
      lv_cbo_buy_plan_acct_no: {}, // MMF매입계좌번호
      lv_cbo_buy_plan_acct_no_items: [], // MMF매입계좌번호 list
      lv_rdo_accn_rsc_apl_yn: {},  // 계좌해지여부

      lv_rdo_fi_gb_items:[],             // fi 구분 목록
      lv_rdo_pension_trans_yn_items: [], // 연금환매구분 목록
      lv_rdo_yn_items: [],               // (공통)여부 목록

      // 정보조회
      lv_rdo_cms_yn: {},           // CMS 신청
      lv_rdo_autoalt_yn: {},       // 자동대체신청
      lv_cal_apply_dt: {},         // 환매체결일
      lv_cal_setl_plan_dt: {},     // 환매결제일
      lv_cal_exp_dt: {},           // 만기일
      lv_ed_recv_fee: {},         // 추징유보세
      lv_ed_outcome_rewd: {},     // 성과보수

      // 정보조회 금액
      lv_ed_paym_possamt: {},    // 전액출금가능금액
      lv_ed_paym_possqty: {},    // 환매가능금액/좌수
      lv_ed_fee: {},             // 환매가능수수료
    
      lv_cbo_bank_cd: {}, // 은행코드
      lv_cbo_save_tp: {}, // 저축유형

      // 계좌정보
      lv_ed_cust_no: {}, // 고객번호
      lv_ed_cust_nm: {}, // 고객명

      lv_cust_info: {}, // 고객정보
      lv_acct_list: [], // 계좌조회목록

      // 검색결과
      lv_tax: {}, // 기타 소득세 외
      lv_pay: {}, // 납입금액 외

      DEPO_ACCT_NO: '29', // 출금지급방법선택- 예수금계좌

      // 고객정보 펼치기/접기
      isActive: false,
      
      lv_basInfo: this.getStore("fsStore").getters.getBasInfo.data,

      ds_rdo_yn: [
        {
          tel_use_yn: '유선',                  // 유선구분
          pension_trans_yn: '타사이전',        // 연금환매구분
          code: 'Y',                          // 값
          yesno: '예',                        // name
          fi_yn: '고객환매',                   // 내부거래구분
          fi_gb: 'C'                          // 내부거래구분값
        },
        {
          tel_use_yn: '일반',
          pension_trans_yn: '일반환매',
          code: 'N',
          yesno: '아니오',
          fi_yn: 'FI환매',
          fi_gb: 'FI'
        }
      ],
      ds_sy0000: [], // MMF계좌목록조회 결과
      ds_sy2000: [], // 약정계좌번호목록조회 결과
      ds_rp2510_1: {},  // 계좌정보 조회
      ds_rp2510_11: [], // 인출가능금액      
      ds_rp2020_3: {},  // 환매신청처리 결과 (F10570215)      
      
      ds_rp2020_1: {},     // (표1) 특정건좌수환매선택시 매입목록
      ds_rp2510_2: {},     // (표2) 계좌정보 조회      

      lv_checkBankCustNm: false, // 예금주 검색결과

      lv_trCode: 'RP2020',
      lv_media: 'I',

      // 버튼상태
      lv_btn_search: true,  // 조회
      lv_btn_confirm: true, // 확인
      lv_btn_cfm: true,     // 확인서 출력버튼
      lv_masked_val: '',    // 마스크 변수 체크

      /***********************************************************************************
       * F10570167 : 개인정보조회
       * F10570202 : 고객정보조회    (selectSY200000_AccnList)
       * F10570208 : 계좌목록조회    (selectSY000000_AcctNoList)
       * F10570213 : 매입내역조회    (selectRP202000_buyList)
       * F10570215 : 환매신청        (insertRP202000_rpus)
       * F10570216 : 예금주조회      (selectRP204000_dpstr) - 필요없음, 김현주 프로 확인
       * F10570155 : 인출가능금액조회 (selectRP251000_Main) - 없음
       * F10570212 : 인출가능금액조회 (selectRP251000_AccnNo) - 유선 시, 필요없음
       * F10570214 : 계좌간편조회     (selectRP251000_TrustSimple) - 유선 시, 필요없음
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    itemsAgent() {
      let rtn = [];
      rtn.push({ value: "Y", text: "예" });
      rtn.push({ value: "N", text: "아니오" });
      return rtn;
    },
    fn_Password: {
      // return FSCommUtil.gfn_isNull(this.lv_ed_secure_password.value)? '' : this.lv_ed_secure_password.value
      get: function() {
        return this.lv_ed_secure_password.value
      },
      set: function(data) {
        this.lv_ed_secure_password.value = data
      }
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 대리인여부
    'lv_rdo_agnt_chk_yn.value': {
      handler(after, before) {
        if( after == 'Y' ) {
          // 대리인 만기체크
          this.fn_AgentCheck()
        }
      },
      deep: true
    },    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SetMaskedTxt
    * 설명       : 보안키패드 체크
    ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if(this.lv_masked_val.length >= 4) {
        this.fn_SearchList()        
      }
    },
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log("fn_SetCommCode...");

      let params = [
        { cId: "322", dayChkYn: "N" }, // 환매구분
        { cId: "989", dayChkYn: "N" }, // 은행코드
        { cId: "231", dayChkYn: "N" }, // 저축유형
        { cId: "342", dayChkYn: "Y" }, // 출금지급방법
        { cId: "303", dayChkYn: "N" }, // 입금구분 - as-is : {code:"303", dsName:"ds_cborecp_gb"}
        { cId: "2020", dayChkYn: "N" }, // 환매제한계좌 - as-is : {code:"2020", dsName:"ds_code2020"}
      ];
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult);
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult;
      console.log(
        "fn_SetCommCodeResult - userGrpTc >",
        this.lv_basInfo.userGrpTc
      );

      let userGrpTc = this.lv_basInfo.userGrpTc;
      let t_codeList = [];
      let t_data = {}
      let arrValue = null
      
      //텔러이외에는 처리불가처리함. 2012.11.07 김재연 (D12100005525_환매화면 처리자 권한 제한 요청)
      // 26:약정이체
      if (userGrpTc !== "19" && userGrpTc !== "99" && userGrpTc !== "82") {
        arrValue = ['29', '31']
      }
      else {
        arrValue = ['29', '31', '26']
      }
      
      arrValue.forEach(item => { 
          t_data = pResult.code342.filter(v=> v.value === item)[0]
          if(!FSCommUtil.gfn_isNull(t_data)){
            t_codeList.push(t_data)
          }
      })

      // 출금지급방법
      this.lv_commCode.code342 = t_codeList;
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     * 파라미터(타입) : type (init - 초기화 시), flag (false - 활성화, true - 비활성화)
     ******************************************************************************/
    fn_Init(type = 'init', flag = true) {
      console.log("[MSPFS310M] fn_Init...");

      // 검색조건
      this.lv_ed_accn_no = FSCommUtil.gfn_dataSet(1,"",false,"","ed_accn_no"); // 계좌번호
      this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1,"",true,"","ed_acct_gb"); // 계좌종류

      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1); // 고객
      this.lv_cbo_save_tp = FSCommUtil.gfn_dataSet(1) // 저축유형

      this.pCustInfoObj.custNo = ''

      // 계좌정보
      this.lv_acct_list = []; // 계좌조회목록
      this.lv_cust_info = {}; // 고객정보

      // 환매신청사항
      this.lv_cbo_redem_gb = FSCommUtil.gfn_dataSet(0, "11"); // 환매구분
      this.lv_cbo_redem_gb_items = this.lv_commCode.code322; // 환매구분 list            

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init();

      this.lv_rdo_ars_gb              = FSCommUtil.gfn_dataSet(0, 'N', false, '')                            // 일반(N) / 유선(Y)
      this.lv_rdo_pension_trans_yn     = FSCommUtil.gfn_dataSet(0, '', true, '')                             // 연금환매구분
      this.lv_ed_secure_password       = FSCommUtil.gfn_dataSet(1, ''  , flag, '', 'ed_secure_password')      // 비밀번호
      this.lv_rdo_agnt_chk_yn          = FSCommUtil.gfn_dataSet(0, 'N', false, '')                            // 대리인여부
      
      // 검색결과
      // as-is : codecolumn -> to-be : value
      // as-is : datacolumn -> to-be : text
      this.lv_rdo_fi_gb_items = []
      this.lv_rdo_pension_trans_yn_items = []
      this.lv_rdo_yn_items = []
      this.ds_rdo_yn.forEach(item => {        
        //fi 구분 목록
        this.lv_rdo_fi_gb_items.push({value: item.fi_gb, text: item.fi_yn}) 
        //연금환매구분 목록
        this.lv_rdo_pension_trans_yn_items.push({value: item.code, text: item.pension_trans_yn})
        //(공통)여부 목록
        this.lv_rdo_yn_items.push({value: item.code, text: item.yesno})
      })

      this.lv_ed_fee = FSCommUtil.gfn_dataSet(1)                              // 환매가능수수료      
      
      // 버튼상태
      this.lv_btn_search = true  // 조회
      this.lv_btn_confirm = true // 확인
      this.lv_btn_cfm     = true // 확인서 출력
      
      this.fn_InitByPart('passv')
      this.fn_InitByPart('rpus')      
      this.fn_InitByPart('info')
      this.fn_InitByPart('tax')
      this.fn_InitByPart('pay')      
      this.fn_InitByPart('2020List')
      this.fn_InitByPart('2510List')
    },
    
    /******************************************************************************
     * Function명 : fn_InitByPart
     * 설명       : 부분별 초기화
     * 파라미터   : part (부분), enableFlag (true - enable, false - disable) ; enableFlag (true - disabled / readonly : false , false - disabled / readonly : true)
     ******************************************************************************/
    fn_InitByPart(part, enableFlag = false) {
      let isDisabled = !enableFlag
      switch(part){
        case 'rpus': // 환매신청사항 init          
          this.fn_InitByPart('redem', false)                                 // 환매구분
          this.fn_InitByPart('bank_accn_no', false)                          // 이체은행
          this.fn_InitByPart('btrans_accn_no', false)                        // 약정계좌번호
          this.fn_InitByPart('mmf_accn_no', false)                           // MMF자동매입계좌번호
          this.fn_InitByPart('depo_acct_no', false)                          // 예수금계좌
          this.lv_btn_cfm              = true                                // 확인서 출력 버튼
          this.lv_cal_std_dt           = FSCommUtil.gfn_dataSet(1, '', true) // 환매예약일자
          this.lv_rdo_fi_gb            = FSCommUtil.gfn_dataSet(1, '', true) // 환매자구분          
          this.lv_rdo_paym_repay_mth   = FSCommUtil.gfn_dataSet(0, this.DEPO_ACCT_NO, true) // 출금지급방법
          this.lv_rdo_accn_rsc_apl_yn  = FSCommUtil.gfn_dataSet(1, '', true) // 계좌해지여부
          
          break
        case 'bank_accn_no': // 이체은행
          this.lv_cbo_bank_cd        = FSCommUtil.gfn_dataSet(1, '', isDisabled, '', 'cbo_bank_cd') // 은행코드 as-is : readonly(!bBool)
          this.lv_ed_btrans_acct_no = FSCommUtil.gfn_dataSet(1, '', isDisabled, '', 'ed_btrans_acct_no') // 은행계좌 as-is : readonly(!bBool)
          this.lv_ed_btrans_acct_nm  = FSCommUtil.gfn_dataSet(1, '', isDisabled, '', 'ed_btrans_acct_nm') // 은행계좌 as-is : readonly(!bBool)
          this.lv_btn_cust_nm        = isDisabled // 예금주확인
          break
        case 'btrans_accn_no': // 약정계좌번호
          this.lv_cbo_btrans_acct_no = FSCommUtil.gfn_dataSet(1, '', isDisabled)
          this.ds_sy2000 = []
          this.lv_cbo_btrans_acct_no_items = []
          break
        case 'mmf_accn_no': // MMF계좌목록
          this.lv_cbo_buy_plan_acct_no = FSCommUtil.gfn_dataSet(1, '', isDisabled)
          this.ds_sy0000 = []
          this.lv_cbo_buy_plan_acct_no_items = []
          break
        case 'depo_acct_no': // 예수금계좌
          this.lv_ed_depo_acct_no = FSCommUtil.gfn_dataSet(1, '', true) // as-is : 값만 초기화
          break
        case 'redem': // 환매구분
          this.lv_cbo_redem_gb = FSCommUtil.gfn_dataSet(1, '', isDisabled)      // 환매구분
          this.lv_cbo_redem_gb_items = []
          this.lv_ed_redem_req_qty = FSCommUtil.gfn_dataSet(1, '', isDisabled, '' , 'ed_redem_req_qty') // 환매신청좌수
          this.lv_rdo_fi_gb.value = 'C'
          if (enableFlag){
            this.lv_rdo_paym_repay_mth.disabled = enableFlag // readonly(!bBool)
            let userGrpTc = this.lv_basInfo.userGrpTc
            console.log('fn_InitByPart - redem - userGrpTc')
            if (userGrpTc == '19'){
              this.lv_rdo_fi_gb.disabled = isDisabled
            }
          }
          this.lv_btn_confirm = isDisabled
          break
        case 'pay':
          this.lv_pay = {
            disabled: isDisabled,
            sum_year_1: '',           // 1년차 납입금액
            lev_incm_tax_year_1: '',  // 1년차 추징소득세
            lev_reci_tax_year_1: '',  // 1년차 추징주민세
            sum_year_2: '',
            lev_incm_tax_year_2: '',
            lev_reci_tax_year_2: '',
            sum_year_3: '',
            lev_incm_tax_year_3: '',
            lev_reci_tax_year_3: '',
            lev_amt: ''
          }
          break
        case 'tax':
          this.lv_tax = {
            disabled: isDisabled,
            inc: '',   // 소득세
            resi: '',  // 주민세
            total: '', // 합산
            levy_inc: '', // 해지소득세
            levy_resi: '', // 해지주민세
            levy_total: '', // 해지합산
          }
          break
        case 'info': // 정보조회
          this.fn_InitByPart('amt')
          this.lv_rdo_cms_yn = FSCommUtil.gfn_dataSet(1, '', isDisabled)                // CMS 신청
          this.lv_rdo_autoalt_yn = FSCommUtil.gfn_dataSet(1, '', isDisabled)            // 자동대체신청
          this.lv_cal_apply_dt = FSCommUtil.gfn_dataSet(1, '', isDisabled)              // 환매체결일
          this.lv_cal_setl_plan_dt = FSCommUtil.gfn_dataSet(1, '', isDisabled)          // 환매결제일
          this.lv_cal_exp_dt = FSCommUtil.gfn_dataSet(1, '', isDisabled)                // 만기일
          break
        case 'amt': // 정보조회 금액 
          this.lv_ed_paym_possamt = FSCommUtil.gfn_dataSet(1, '', isDisabled)          // 전액출금가능금액
          this.lv_ed_paym_possqty = FSCommUtil.gfn_dataSet(1, '', isDisabled, '', 'ed_paym_possqty')          // 환매가능금액/좌수
          this.lv_ed_fee = FSCommUtil.gfn_dataSet(1, '', isDisabled)                   // 환매가능수수료
          this.fn_InitByPart('recv', false)            // 추징유보세
          this.fn_InitByPart('outcome', false)         // 성과보수
          break
        case 'recv': // 정보조회 금액 - 추징유보세
          this.lv_ed_recv_fee = FSCommUtil.gfn_dataSet(1, '', isDisabled)
          break
        case 'outcome': // 정보조회 금액 - 성과보수
          this.lv_ed_outcome_rewd = FSCommUtil.gfn_dataSet(1, '', isDisabled)
          break
        case 'passv': // 비밀번호
          this.lv_ed_secure_password = FSCommUtil.gfn_dataSet(1, '', isDisabled)
          this.lv_isClear = !this.lv_isClear ? true : false // 보안키패드 초기화
          break
        case '2020List': // 입금 목록
          this.ds_rp2020_1 = {
            disabled: isDisabled,
            value: [],
            selIdx: ''
          }
          break
        case '2510List': // 매입목록
          this.ds_rp2510_2 = {
            disabled: isDisabled,
            value: []
          }
          break

      }
    },
    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event, flag) {
      console.log("fn_SearchValid.....");

      // 계좌목록/고객명 초기화
      this.lv_acct_list = [];
      this.lv_ed_cust_nm.value = "";

      // 고객정보 펼치기 정보 초기화
      this.$refs.fsCustInfo.fn_Init();

      let t_accn_no = this.lv_ed_accn_no.value;
      // if (flag == "P") {
      //   t_accn_no = (event.clipboardData || window.clipboardData)
      //     .getData("text")
      //     .replace(/[^0-9]/g, "")
      //     .substr(0, 10);
      // } else {
      //   if(!FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)){
      //     t_accn_no = this.lv_ed_accn_no.value
      //       .replace(/[^0-9]/g, "")
      //       .substr(0, 10)
      //   }
      // }

      if (FSCommUtil.gfn_length(t_accn_no) >= 10) {
        this.lv_ed_accn_no.error = false;

        if (!FSCommUtil.gfn_isNum(t_accn_no)) {
          FSCommUtil.gfn_validate(this, "계좌번호 형식에 맞지 않습니다.");
          this.lv_ed_accn_no.error = true;
          this.$refs["ed_accn_no"].focus();
          return;
        } else {
          if (
            FSCommUtil.gfn_isNum(event.key) ||
            event.key == "v" ||
            flag == "P" ||
            this.isMobile
          ) {
            // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no);

            // 계좌목록조회
            this.fn_AccnNoList(t_accn_no);            
          }
        }
      } else {
        // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no);
      }
    },

    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type)
        0: 고객정보        
        MSPFS313P: 업무대리인 조회        
      ******************************************************************************/
    fn_OpenPopup(type, pData, subId) {
      let t_popupObj = {}

      switch (type) {
        case 'MSPFS313P':
          // 업무대리인 조회 팝업
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break

      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) 
        0: 고객정보                 
        MSPFS313P: 업무대리인 조회        
      ******************************************************************************/
    fn_Popup_CallBack(type, pData, pSubId) {
      switch (type) {
        case 'MSPFS313P':
            console.log('업무대리인 callback...')

            // 고객번호 매핑 후 조회
            this.lv_ed_cust_no.value = pData.cust_no

            this.fn_SearchValid({key: 'v'}, 0)
            break
      }
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive;
    },

    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log("fn_CustInfo.....");

      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0,7)
      this.$refs.fsCustInfo.fn_CustInfoSearch();
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.lv_ed_cust_nm.value = val;
    },

    /******************************************************************************
     * Function명 : fn_AccnNoList, fn_AccnNoListResult
     * 설명       : 계좌목록조회
     ******************************************************************************/
    fn_AccnNoList(pAccnNo) {
      console.log("fn_AccnNoList.....");

      let t_data = {        
        input_check: 'D',
        int_tr_yn: 'N',
        bfcer_accn_stat_cd: 'Y',
        bfcer_cstgr_no: pAccnNo
      }      
      FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult);
    },

    fn_AccnNoListResult(pResultData) {
      console.log("fn_AccnNoListResult.....");

      let t_data = pResultData.data.sy000000_O_00VO;
      console.log(t_data);

      // 계좌목록조회 초기화
      this.lv_acct_list = [];
      this.lv_ed_acct_gb.disabled = true;

      if (t_data.length > 0) {
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({
            value: idx,
            text: item.acct_no_nm,
            bfcer_sav_typ_cd: item.bfcer_sav_typ_cd,          // 저축유형
            fnd_ivt_typ_cd: item.fnd_ivt_typ_cd,              // 펀드투자유형코드
            bfcer_taxsy_typ_sc_cd: item.bfcer_taxsy_typ_sc_cd,// 세제유형구분코드
            hdge_fnd_yn: item.hdge_fnd_yn,
            bfcer_accn_no: item.bfcer_accn_no,
            bfcer_apamt_accn_no: item.bfcer_apamt_accn_no
          });
        });

        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0;
        this.lv_ed_acct_gb.disabled = false;

        // 저축유형코드 설정
        let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
        this.lv_cbo_save_tp.value = tmpRow.bfcer_sav_typ_cd

         // 버튼상태
        this.lv_btn_search = false  // 조회
        this.lv_btn_confirm = false // 확인

        // 고객정보조회
        this.fn_CustInfo();
      } else {
        let t_popupObj = { content: "검색할 계좌가 없습니다." };
        this.fn_AlertPopup(0, t_popupObj);
      }
    },

    /******************************************************************************
     * Function명 : fn_AgentCheck
     * 설명       : 대리인 만기체크
     ******************************************************************************/
    fn_AgentCheck() {
      console.log('fn_AgentCheck...')      
      let tmpCustNo = this.pCustInfoObj.custNo

      // 초기화
      this.lv_ed_accn_no.error = false
      if( FSCommUtil.gfn_isNull(tmpCustNo) ) {
        FSCommUtil.gfn_validate(this, '고객정보가 조회되지 않았습니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = 'N'
        return
      }

      let t_params = {
        // PO 호출 조회조건
        bfcer_cust_no : tmpCustNo,
        tr_code : 'RP202000',

        // 추가정보
        menu_id : 'MSPFS310M',
        btn_id: 'lv_rdo_agnt_chk_yn',
      }
      FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
    },
    /******************************************************************************
     * Function명 : fn_AcctNoCall
     * 설명       : 조회조건 계좌번호 검색 Callback 함수
     * as-is func. : fn_Acct_no_call
     ******************************************************************************/
    fn_AcctNoCall() {
      this.lv_ed_secure_password.value = ''
      this.lv_isClear = !this.lv_isClear ? true : false

      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]      
      this.lv_cbo_save_tp.value = tmpRow.bfcer_sav_typ_cd

      //계좌목록
      this.ds_sy2000 = []      
      this.ds_sy0000 = []

      //환매조회
      this.ds_rp2510_1 = []
      this.ds_rp2510_2.value = []

      //as-is : 보고서 dataset 
	    // this.ds_rp202000_o_03vo.clearData();
      
      this.fn_InitByPart('rpus')
      this.fn_InitByPart('info')
      this.fn_InitByPart('tax', false)
      this.fn_InitByPart('pay', false)
      this.fn_InitByPart('2020List', false)
      this.fn_InitByPart('2510List', false)

      if(this.lv_basInfo.userGrpTc === '19'){
        this.lv_rdo_fi_gb.disabled = false
      }

      let tmpAccnNo = this.lv_ed_accn_no.value    // 계좌번호 체크  
                      .replace(/[^0-9]/g, "")
                      .substr(0, 10)
      if (!this.fn_ChkCompAnnuAccnNo(tmpAccnNo)){
        console.log('fn_ValidationSearch > ', '[' + tmpAccnNo + '] 환매 제한 계좌')
      }
    },
    /******************************************************************************
     * Function명 : fn_ValidationSearch
     * 설명       : 조회버튼 입력값 검증     
     ******************************************************************************/
    fn_ValidationSearch() {
      // 계좌번호 체크
      let tmpAccnNo = this.lv_ed_accn_no.value      
                      .replace(/[^0-9]/g, "")
                      .substr(0, 10);      
      if( FSCommUtil.gfn_isNull(tmpAccnNo) ) {
        FSCommUtil.gfn_validate(this, '계좌번호를 입력해 주십시오.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return false         
      } else if( FSCommUtil.gfn_length(tmpAccnNo) != 10 || !FSCommUtil.gfn_isNum(tmpAccnNo) ) {
        FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return false
      }

      if (!this.fn_ChkCompAnnuAccnNo(tmpAccnNo)){
        console.log('fn_ValidationSearch > ', '[' + tmpAccnNo + '] 환매 제한 계좌')
      }

      // 비밀번호 체크
      if(this.lv_rdo_ars_gb.value !== 'Y'){ // 일반(N) / 유선(Y) 이면 비밀번호 필요
        if(this.isMobile && this.isMtrans) {
          if ( FSCommUtil.gfn_length(this.lv_masked_val) != 4 ) {
            FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
            this.lv_ed_secure_password.error = true
            return false
          }          
        } else {
          if( FSCommUtil.gfn_isNull(this.lv_ed_secure_password.value) || FSCommUtil.gfn_trim(this.lv_ed_secure_password.value).length < 4 ) {
            FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
            this.lv_ed_secure_password.error = true
            this.$refs['ed_secure_password'].focus()
            return false    
          }
        }
      }

      if( FSCommUtil.gfn_isNull(this.lv_rdo_agnt_chk_yn.value) ) {
        FSCommUtil.gfn_validate(this, '대리인여부을 선택하십시오.')
        this.lv_rdo_agnt_chk_yn.error = true      
        return false     
      }

      return true
    },
    /******************************************************************************
     * Function명 : fn_ChkPwd 
     * 설명       : 비밀번호 입력 완료시 처리  
     ******************************************************************************/
    fn_ChkPwd() {
      let t_pwd = this.lv_ed_secure_password.value
      if (FSCommUtil.gfn_length(t_pwd) == 4) {
        this.fn_SearchList()
      }
    },
    /******************************************************************************
     * Function명 : fn_SearchList 
     * 설명       :계좌정보 조회     
     ******************************************************************************/
    fn_SearchList() {
      console.log('fn_SearchList...')
      if(!this.fn_ValidationSearch()) {
        return false
      }

      this.lv_ed_secure_password.error = false

      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      let t_bfcer_accn_no = tmpRow.bfcer_accn_no      
      this.fn_InitByPart('redem', true)
      this.fn_InitByPart('depo_acct_no', true)      
      this.ds_rp2510_1 = {}
      this.lv_cbo_btrans_acct_no_items = []      
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      let tmpCustNo = FSCommUtil.gfn_trim(this.lv_ed_accn_no.value).substr(0,7)
      let tmpEaiId = tmpCustNo !== '1009176' ? 'F10570212' : 'F10570214' // as-is : selectRP251000_AccnNo(F10570212) / selectRP251000_TrustSimple(F10570214)
      this.eaiCommObj.commHeaderVO.eaiId =  'C391_' + tmpEaiId +'_S'
      this.eaiCommObj.params = {
        tr_code: this.lv_trCode,
        media: this.lv_media,        
        bfcer_accn_no  : t_bfcer_accn_no,
        bfcer_rpus_sc_cd  : '11',
        secure_passv  : this.lv_ed_secure_password.value,
        ars_pswn_yn   : ''
      }
      console.log('fn_SearchList - params', this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchListResult)
    },
    fn_SearchListResult (pResultData) {
        console.log('fn_SearchListResult...')
        let t_data = pResultData.data
        console.log(t_data)

        if (!FSCommUtil.gfn_isNull(t_data)) {
          if( t_data.error_msg == '809900' || t_data.error_msg == ' ') {   

            this.ds_rp2510_1 = t_data          
            // 빈 값으로 올때 처리
            for (const item in this.ds_rp2510_1){
              let value = this.ds_rp2510_1[item]            
              if(!FSCommUtil.gfn_isNull(value)){
                if(FSCommUtil.gfn_trim(value) !== value) {
                  this.ds_rp2510_1[item] = ''
                }
              }
            }     
            /**
             * 정보조회
             * <Bind>
                <BindItem id="item5" compid="div_detail.div_right.rdo_cms_yn" propid="value" datasetid="ds_rp251000_o_01vo" columnid="cms_yn"/>
                <BindItem id="item6" compid="div_detail.div_right.rdo_autoalt_yn" propid="value" datasetid="ds_rp251000_o_01vo" columnid="autoalt_yn"/>
                <BindItem id="item7" compid="div_detail.div_right.cal_apply_dt" propid="value" datasetid="ds_rp251000_o_01vo" columnid="apply_dt"/>
                <BindItem id="item8" compid="div_detail.div_right.cal_setl_plan_dt" propid="value" datasetid="ds_rp251000_o_01vo" columnid="setl_plan_dt"/>
                <BindItem id="item9" compid="div_detail.div_right.cal_exp_dt" propid="value" datasetid="ds_rp251000_o_01vo" columnid="exp_dt"/>
                <BindItem id="item2" compid="div_detail.div_right.mae_paym_possamt" propid="value" datasetid="ds_rp251000_o_01vo" columnid="paym_possamt"/>
                <BindItem id="item0" compid="div_detail.div_right.mae_paym_possqty" propid="value" datasetid="ds_rp251000_o_01vo" columnid="paym_possqty"/>
              </Bind>
            */
            
            this.lv_ed_paym_possamt.value = this.ds_rp2510_1.paym_possamt // 전액 출금가능금액
            this.lv_ed_paym_possqty.value = this.ds_rp2510_1.paym_possqty // 환매가능금액/좌수
            
            this.lv_rdo_cms_yn.value = !FSCommUtil.gfn_isNull(this.ds_rp2510_1.cms_yn)? this.ds_rp2510_1.cms_yn : 'N'// cms신청
            this.lv_rdo_autoalt_yn.value = !FSCommUtil.gfn_isNull(this.ds_rp2510_1.autoalt_yn)? this.ds_rp2510_1.autoalt_yn : 'N' // 자동대체신청          
            this.lv_cal_apply_dt.value = FSCommUtil.gfn_dateReplace(1,this.ds_rp2510_1.apply_dt) // 환매체결일
            this.lv_cal_setl_plan_dt.value = FSCommUtil.gfn_dateReplace(1,this.ds_rp2510_1.setl_plan_dt) // 환매결제일
            this.lv_cal_exp_dt.value = FSCommUtil.gfn_dateReplace(1,this.ds_rp2510_1.exp_dt) // 만기일

            this.lv_tax.inc   = this.ds_rp2510_1.inc_tax // 기타소득세 
            this.lv_tax.resi  = this.ds_rp2510_1.resi_tax // 기타주민세           
            this.lv_tax.total = parseFloat(this.ds_rp2510_1.inc_tax) + parseFloat(this.ds_rp2510_1.resi_tax)
            this.lv_tax.levy_inc   = this.ds_rp2510_1.levy_inc_tax // 해지소득세
            this.lv_tax.levy_resi  = this.ds_rp2510_1.levy_resi_tax // 해지주민세
            this.lv_tax.levy_total = parseFloat(this.ds_rp2510_1.levy_inc_tax) + parseFloat(this.ds_rp2510_1.levy_resi_tax)
            console.log('tax >>', this.lv_tax)                                  
            this.fn_SetConfirmData()
          }
          else {
            let t_valList = []
            let t_popupObj = {};
            t_popupObj.content = FSMessageUtil.commMessage(t_valList, t_data.error_msg)[t_data.error_msg.split('||')[0]]
            this.fn_AlertPopup(0, t_popupObj)
          }
        } else {
          this.fn_AlertPopup(0, {content: '타연계 서버 오류'})
        }
    },
    /******************************************************************************
     * Function명 : fn_SetConfirmData 
     * 설명       :환매신청정보 셋팅    
     ******************************************************************************/
    fn_SetConfirmData() {

      let recvFeeYn = 'N' // 추징유보세
      this.fn_SetTaxSyTypeSc(this.ds_rp2510_1.orign_amtyn)

      //------------------------------------------------------
      //세제유형
      //------------------------------------------------------
      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      let tmpTaxsyTypSc = tmpRow.bfcer_taxsy_typ_sc_cd
      // tmpTaxsyTypSc = '13'

      switch(tmpTaxsyTypSc){
        case '8': // 장기주식형펀드
          // 납입금액 표 테스트
          // this.ds_rp2510_1.sum_year01 = '1000'
          // this.ds_rp2510_1.sum_year02 = '1000'
          // this.ds_rp2510_1.sum_year03 = '1000'
          // this.ds_rp2510_1.trmt_lev_incm_tax01 = '1000'
          // this.ds_rp2510_1.trmt_lev_resi_tax01 = '1000'
          // this.ds_rp2510_1.trmt_lev_incm_tax02 = '1000'
          // this.ds_rp2510_1.trmt_lev_resi_tax02 = '1000'
          // this.ds_rp2510_1.trmt_lev_incm_tax03 = '1000'
          // this.ds_rp2510_1.trmt_lev_resi_tax03 = '1000'
          // this.ds_rp2510_1.levy_amt = '1000'

          let sum_year01 = this.ds_rp2510_1.sum_year01
          let sum_year02 = this.ds_rp2510_1.sum_year02
          let sum_year03 = this.ds_rp2510_1.sum_year03          
          let sum_year = parseFloat(sum_year01) + parseFloat(sum_year02) + parseFloat(sum_year03)
          let save_padin_gb = this.ds_rp2510_1.save_padin_gb

          if(sum_year > 0){
            this.fn_InitByPart('pay', true)
            
            let tmp_pay = {           
              sum_year_1: this.ds_rp2510_1.sum_year01,                      // 1년차 납입금액
              lev_incm_tax_year_1: this.ds_rp2510_1.trmt_lev_incm_tax01,    // 1년차 추징소득세
              lev_reci_tax_year_1: this.ds_rp2510_1.trmt_lev_resi_tax01,    // 1년차 추징주민세
              sum_year_2: this.ds_rp2510_1.sum_year02,                      // 2년차 납입금액
              lev_incm_tax_year_2: this.ds_rp2510_1.trmt_lev_incm_tax02,    // 2년차 추징소득세
              lev_reci_tax_year_2: this.ds_rp2510_1.trmt_lev_resi_tax02,    // 2년차 추징주민세
              sum_year_3: this.ds_rp2510_1.sum_year03,                      // 3년차 납입금액
              lev_incm_tax_year_3: this.ds_rp2510_1.trmt_lev_incm_tax03,    // 3년차 추징소득세
              lev_reci_tax_year_3: this.ds_rp2510_1.trmt_lev_resi_tax03,    // 3년차 추징주민세
              lev_amt: this.ds_rp2510_1.levy_amt
            }

            this.lv_pay = tmp_pay
            this.lv_btn_cfm = false
            // as-is : this.div_commBtns.div_btnList.btn_cfmPrnt.set_visible(true)
            if (save_padin_gb === 'Y'){
              // as-is : this.gfn_openPopup("RP202001P","rp::RP202001P.xfdl", null, "");
            }
          }
          break
        case '7': // 연금저축
        case '13':
          this.fn_InitByPart('tax', true)
          this.lv_tax.inc =  this.ds_rp2510_1.inc_tax
          this.lv_tax.resi = this.ds_rp2510_1.resi_tax
          this.lv_tax.total  = parseFloat(this.ds_rp2510_1.inc_tax) + parseFloat(this.ds_rp2510_1.resi_tax)
          this.lv_tax.levy_inc = this.ds_rp2510_1.levy_inc_tax
          this.lv_tax.levy_resi = this.ds_rp2510_1.levy_resi_tax
          this.lv_tax.levy_total  = parseFloat(this.ds_rp2510_1.levy_inc_tax) + parseFloat(this.ds_rp2510_1.levy_resi_tax)

          let t_inc_tax = Number(this.ds_rp2510_1.inc_tax)          
          let t_levy_inc_tax = Number(this.ds_rp2510_1.levy_inc_tax)          
          if(t_inc_tax > 0 && t_levy_inc_tax > 0){
            this.fn_AlertPopup(0, {content:'기타소득세 및 해지세 발생'})
          } else if(t_inc_tax > 0 && t_levy_inc_tax == 0){
            this.fn_AlertPopup(0, {content:'기타소득세 발생'})
          } else if(t_inc_tax == 0 && t_levy_inc_tax > 0){
            this.fn_AlertPopup(0, {content:'해지세 발생'})
          }
          recvFeeYn = 'Y'
          break
        case '6':  // 장기주택마련저축
        case '11': // 재형저축
        case '15': // 소득공제장기펀드
          this.fn_InitByPart('tax', true)
          this.lv_tax.levy_inc = this.ds_rp2510_1.levy_inc_tax
          this.lv_tax.levy_resi = this.ds_rp2510_1.levy_resi_tax
          this.lv_tax.levy_total  = parseFloat(this.ds_rp2510_1.levy_inc_tax) + parseFloat(this.ds_rp2510_1.levy_resi_tax)

          if(tmpTaxsyTypSc === '15'){
            // as-is
            // this.div_commBtns.div_btnList.btn_cfmPrnt.set_visible(true)
            this.lv_btn_cfm = false
          }
          recvFeeYn = 'Y'
          break
      }

      //------------------------------------------------------
      // 환매수수료
      //------------------------------------------------------      
      let t_fee = Number(this.ds_rp2510_1.fee)
      this.lv_ed_fee.value = t_fee < 0 ? 0 : t_fee
      if(t_fee > 0){
        this.fn_AlertPopup(0,{content: '환매수수료가 존재합니다 고객님께 수수료를 안내하세요!'})
      }

      //------------------------------------------------------
      // 헤지펀드, 추징유보세
      //------------------------------------------------------            
      let hedgeYn = tmpRow.hdge_fnd_yn      
      let tmpRecvEnable = recvFeeYn === 'Y'? true : false  // 추징유보세 여부
      let tmpOutcomeEnable = hedgeYn === 'Y'? true : false // 성과보수 여부
      this.fn_InitByPart('recv', tmpRecvEnable)
      this.fn_InitByPart('outcome', tmpOutcomeEnable)

      if (recvFeeYn === 'Y') {
        this.lv_ed_recv_fee.value = this.ds_rp2510_1.recv_fee  // 추징유보세
      }

      if (hedgeYn === 'Y') {        
        this.lv_ed_outcome_rewd.value = this.ds_rp2510_1.outcome_rewd // 성과보수

        let tmpContentTitle = ''
        let tmpContent = []
        tmpContent.push('환매대금지급시 성과보수가 발생할 수 있으며, 좌수/전액환매만 가능합니다.')
        tmpContent.push('성과보수는 환매체결일 기준가에 따라 변경됩니다.')        
        
        let t_popupObj = {
          confirm: false,          
          content: tmpContent,
          contentTitle: tmpContentTitle
        }

        this.fn_AlertPopup(7, t_popupObj)
      }

      this.lv_rdo_paym_repay_mth.disabled = false
      this.lv_rdo_paym_repay_mth.value = this.DEPO_ACCT_NO
      this.fn_ChangeRdoPaymRepayMth(this.DEPO_ACCT_NO)   
      // 2023-09-07 계좌번호 오토포커싱 삭제    
      // this.$refs['ed_accn_no'].focus()      
    },
    /******************************************************************************
     * Function명 : fn_GetSum 
     * 설명       : 문자열을 숫자로 변경하고 합산한 결과 도출
     * 파라미터   : 합해야 할 값들
     * 리턴       : 합산 결과 (콤마 표시)
     ******************************************************************************/
    fn_GetSum(val1, val2) {      
      let rtn = 0
      let tmpVal1 = 0
      let tmpVal2 = 0
      if(!FSCommUtil.gfn_isNull(val1)){        
        tmpVal1 = Number(val1)  
      } else {
        tmpVal1 = 0
      }

      if(!FSCommUtil.gfn_isNull(val2)){        
        tmpVal2 = Number(val2)  
      } else {
        tmpVal2 = 0
      }
      
      rtn = tmpVal1 + tmpVal2
      return rtn
    },
    /******************************************************************************
     * Function명 : fn_SetTaxSyTypeSc 
     * 설명       :세제유형에 따른 환매구분목록
     * 파라미터   : 항목구분
     ******************************************************************************/
    fn_SetTaxSyTypeSc(orign_amtyn) {      
      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      let tmpTaxsyTypSc = tmpRow.bfcer_taxsy_typ_sc_cd
      let tmpHedgeYn = tmpRow.hdge_fnd_yn // 해지펀드확인
      let tmpBfcerAccnNo = tmpRow.bfcer_accn_no
      let tmpFundTp = tmpRow.fnd_ivt_typ_cd   // 펀드투자유형코드   
      if(FSCommUtil.gfn_isNull(tmpFundTp)){
        tmpFundTp = ''
      }

      switch(tmpTaxsyTypSc){
        case '7': // (구)연금저축
          this.lv_rdo_pension_trans_yn.value = ''
          this.lv_rdo_pension_trans_yn.disabled = false
          this.fn_AlertPopup(0,{content: '특별중도해지(사망,퇴직,해외이주 등)에 해당되는 경우 본사로 연락부탁드립니다.'})
          break
        case '2': // 세금우대
        case '6': // 장기주택마련저축        
        case '11': // 재형저축
          this.fn_AlertPopup(0,{content: '특별중도해지(사망,퇴직,해외이주 등)에 해당되는 경우 본사로 연락부탁드립니다.'})
          break
      }
      let t_codeList = []

      // 환매구분코드 변경
      switch(tmpTaxsyTypSc){
        case '6': // 장기주택마련저축
        case '7': // (구)연금저축
        case '8': // 장기주식형펀드
        case '9': // 장기회사채형펀드
        case '11': // 재형저축
        case '13': // 연금저축
        case '15': // 소득공제장기펀드
        case '19': // 코스닥벤처
          t_codeList = this.lv_commCode.code322.filter(v=>v.value === '11')
          break
        case '18':
          t_codeList = this.lv_commCode.code322.filter(v=>v.value === '12')
          break
        default:
          if(tmpBfcerAccnNo.substr(0,7) === '1009176'){
            if(tmpHedgeYn === 'Y'){
              if(orign_amtyn === 'Y'){
                t_codeList = this.lv_commCode.code322.filter(v=> v.value === '11' || v.value === '16' || v.value === '18')
              }else{
                t_codeList = this.lv_commCode.code322.filter(v=> v.value === '11' || v.value === '16')
              }
            }else{
              if(orign_amtyn === 'Y'){
                t_codeList = []
              }else{
                t_codeList = this.lv_commCode.code322.filter(v=> v.value !== '18')
              }
            }
          } else {
            if(tmpHedgeYn === 'Y'){
              t_codeList = this.lv_commCode.code322.filter(v=> v.value === '11' || v.value === '12')
            }else{
              if(orign_amtyn === 'Y'){
                t_codeList = this.lv_commCode.code322.filter(v=> v.value !== '16')
              }else{
                t_codeList = this.lv_commCode.code322.filter(v=> v.value !== '16' && v.value !== '18')
              }
            }
          }
          break
      }

      // to-be 추가 (16, 18 제외) 
      t_codeList = t_codeList.filter(v=> v.value !== '16' && v.value !== '18')

      this.lv_cbo_redem_gb_items = t_codeList
    },
    /******************************************************************************
     * Function명 : fn_ValidateConfirm
     * 설명       : 확인버튼 입력값 검증
     ******************************************************************************/
    fn_ValidateConfirm() {      
      if (!this.fn_ValidationSearch()) {
        return false
      }

      if (this.ds_rp2510_1.length === 0){
        FSCommUtil.gfn_validate(this, '출금가능한 금액이 조회되지 않았습니다.')
      }

      if( FSCommUtil.gfn_isNull(this.lv_ed_paym_possqty.value) && this.lv_cbo_redem_gb.value != '11') {
        FSCommUtil.gfn_validate(this, '환매신청금액(좌수)를 등록하세요')
        this.lv_ed_paym_possqty.error = true
        this.$refs['ed_paym_possqty'].focus()
        return false         
      }

      if( FSCommUtil.gfn_isNull(this.lv_rdo_agnt_chk_yn.value) ) {
        FSCommUtil.gfn_validate(this, '대리인여부을 선택하십시오.')
        this.lv_rdo_agnt_chk_yn.error = true      
        return false     
      }

      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      if( FSCommUtil.gfn_isNull(tmpRow) ) {
        FSCommUtil.gfn_validate(this, '계좌번호 정보가 없습니다.')
        return false
      }
      
      // 펀드 투자유형코드      
      let tmpFundTp = tmpRow.fnd_ivt_typ_cd      
      if( FSCommUtil.gfn_isNull(tmpFundTp) ) tmpFundTp = ''

      // 세제유형
      let tmpTaxTpGb = tmpRow.bfcer_taxsy_typ_sc_cd
      if( FSCommUtil.gfn_isNull(tmpTaxTpGb) ) tmpTaxTpGb = ''

      // 연금환매구분
      let tmpPensionTransYn = this.lv_rdo_pension_trans_yn.value 
      if( FSCommUtil.gfn_isNull(tmpPensionTransYn) ) tmpPensionTransYn = ''

      // 환매구분  
      let tmpRedemGb = this.lv_cbo_redem_gb.value 
      if( FSCommUtil.gfn_isNull(tmpRedemGb) ) tmpRedemGb = ''

      // 지급구분
      let tmpPaymRepayMth = this.lv_rdo_paym_repay_mth.value
      if( FSCommUtil.gfn_isNull(tmpPaymRepayMth) ) tmpPaymRepayMth = ''

      // 펀드투자유형코드 해외(3) X
      if (tmpFundTp != '3') {        
        // 연금(7)일 경우 '연금 타사이전 여부' 필수 입력
        if (tmpTaxTpGb == '7') {
          if (tmpPensionTransYn == ''){
            FSCommUtil.gfn_validate(this, '연금저축의 경우 연금환매구분을 선택하십시오.')
            this.lv_rdo_pension_trans_yn.error = true
            return false
          } else if (tmpPensionTransYn == 'Y' && tmpPensionTransYn == '31'){
            FSCommUtil.gfn_validate(this, "연금타사이전인 경우 출금지급방법 'MMF자동매입'으로 선택하실 수 없습니다.")
            this.lv_rdo_paym_repay_mth.error = true
            return false
          }
        }
      }

      // 환매구분
      // let tmpRedemGb = this.lv_cbo_redem_gb.value
      if( FSCommUtil.gfn_isNull(tmpRedemGb) ) tmpRedemGb = ''
      if( FSCommUtil.gfn_trim(tmpRedemGb).length != 2 ){
        FSCommUtil.gfn_validate(this, '환매구분 입력오류입니다.')
        this.lv_cbo_redem_gb.error = true
        return false
      }

      // 환매신청금액좌수
      let tmpRedemReqQty = this.lv_ed_redem_req_qty.value
      if( FSCommUtil.gfn_isNull(tmpRedemReqQty) ) tmpRedemReqQty = 0

      switch(tmpRedemGb){
        case '11': // 전체환매
          break
        case '16': // 특정건환매 (사용안함)
          let tmpRow = this.ds_rp2020_1.value.filter(v=> v.chk_yn === '1')
          if(FSCommUtil.gfn_isNull(tmpRow)){
            FSCommUtil.gfn_validate(this, '환매신청할 거래내역을 체크하셔야 합니다.')
            return false
          }
          
          if(parseFloat(tmpRow.bfcer_blc) < parseFloat(tmpRedemReqQty)){ // ds_rp2020_1
            FSCommUtil.gfn_validate(this, '선택한 환매좌수보다 큽니다.')
            return false
          }
          break
        case '18': // 매입건별 (사용안함)
          let bfcer_blc = this.lv_sel_rp2020_1.orign_remn_qty // ds_rp2020_1
          if(parseFloat(bfcer_blc) < parseFloat(tmpRedemReqQty)){
            FSCommUtil.gfn_validate(this, '신청한 환매좌수가 환매가능좌수보다 큽니다.')
            return false
          }
          break
        default:
          if(tmpRedemReqQty === 0){            
            FSCommUtil.gfn_validate(this, '환매신청금액(좌수) 입력오류입니다.')            
            this.lv_ed_redem_req_qty.error = true
            this.$refs["ed_redem_req_qty"].focus()
            return false
          }
          break
      }

      // 출금지급방법
      switch(tmpPaymRepayMth){
        case '31': // MMF
          if(FSCommUtil.gfn_isNull(this.lv_cbo_buy_plan_acct_no.value)){
            FSCommUtil.gfn_validate(this, '환매대금MMF자동매입 신청 시 MMF자동매입계좌번호를 선택하세요.')
            this.lv_cbo_buy_plan_acct_no.error = true
            return false
          }
          break
        case '26': // 약정이체
          if(FSCommUtil.gfn_isNull(this.lv_cbo_btrans_acct_no.value)){
            FSCommUtil.gfn_validate(this, '약정계좌번호를 선택하세요.')
            this.lv_cbo_btrans_acct_no.error = true
            return false
          }
          break        
        case this.DEPO_ACCT_NO: // 예수금(브릿지계좌)          
          if(FSCommUtil.gfn_isNull(this.lv_ed_depo_acct_no.value) || FSCommUtil.gfn_trim(this.lv_ed_depo_acct_no.value).length !== 15){            
            FSCommUtil.gfn_validate(this, '예수금(브릿지계좌)가 입력되지 않았습니다.')
            this.lv_ed_depo_acct_no.error = true            
            return false
          }
          break
        case '21': // 이체은행
          // if(!this.fn_ValidateBankCustNm()){
          //   return false
          // }

          // if(!this.lv_checkBankCustNm){
          //   FSCommUtil.gfn_validate(this, '예금주를 확인하세요.')
          //   return false
          // }          
          break
        default:
          FSCommUtil.gfn_validate(this, '출금지급방법을 선택하세요.')
          return false
          break
      }
      return true
    },

    /******************************************************************************
     * Function명 : fn_TransBankChange
     * 설명       : 예금주검색결과     
     ******************************************************************************/
    fn_TransBankChange(flag) {
      this.lv_checkBankCustNm = flag
    },
    /******************************************************************************
     * Function명 : fn_ValidateBankCustNm
     * 설명       : 예금주 확인
     * 사용여부   : 사용안함
     ******************************************************************************/
    fn_ValidateBankCustNm(){
      if( FSCommUtil.gfn_isNull(this.lv_cbo_bank_cd.value) || FSCommUtil.gfn_trim(this.lv_cbo_bank_cd.value) != 3) {
        FSCommUtil.gfn_validate(this, '은행을 입력오류입니다.')
        this.lv_cbo_bank_cd.error = true
        this.$refs["cbo_bank_cd"].focus()
        return false         
      }

      if( FSCommUtil.gfn_isNull(this.lv_ed_btrans_acct_no.value) || FSCommUtil.gfn_trim(this.lv_ed_btrans_acct_no.value) != 3) {
        FSCommUtil.gfn_validate(this, '은행 입금 계좌 입력오류입니다.')
        this.lv_ed_btrans_acct_no.error = true
        this.$refs["ed_btrans_acct_no"].focus()
        return false         
      }

      if( FSCommUtil.gfn_isNull(this.lv_ed_btrans_acct_nm.value) || FSCommUtil.gfn_trim(this.lv_ed_btrans_acct_nm.value) != 3) {
        FSCommUtil.gfn_validate(this, '예금주명을 입력해 주십시오.')
        this.lv_ed_btrans_acct_nm.error = true
        this.$refs["ed_btrans_acct_nm"].focus()
        return false         
      }

      // as-is 계좌번호와 고객명을 비교함 (as-is : String(sBTransAcctNo).trim() == this.div_search.div_accn_no.Ed_cust_nm) -> 변경
      if( FSCommUtil.gfn_trim(this.lv_ed_btrans_acct_nm.value) === this.lv_ed_cust_nm) {
        FSCommUtil.gfn_validate(this, '본인이체를 이용해주세요.')
        this.lv_ed_btrans_acct_nm.error = true
        this.$refs["ed_btrans_acct_nm"].focus()
        return false         
      }

      return true
    },
    /******************************************************************************
     * Function명 : fn_ChangeCboRedemGb
     * 설명       : 환매구분선택     
     ******************************************************************************/    
    fn_ChangeCboRedemGb(){
      this.fn_InitByPart('2020List', false) // 입금목록
      this.fn_InitByPart('2510List', false) // 매입목록(재투자분포함)
      this.lv_ed_redem_req_qty.value = 0

      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      if( FSCommUtil.gfn_isNull(tmpRow) ) {
        FSCommUtil.gfn_validate(this, '계좌번호 정보가 없습니다.')
        return false
      }

      //펀드투자유형코드
      let tmpFundTp = tmpRow.fnd_ivt_typ_cd
      if(FSCommUtil.gfn_isNull(tmpFundTp)){
        tmpFundTp = ''
      }

      //저축유형      
      let tmpSaveTp = this.lv_cbo_save_tp.value
      if(FSCommUtil.gfn_isNull(this.lv_cbo_save_tp.value)){
        tmpFundTp = '' // ? as-is 에서는 저축 유형이 null 이면, 펀트투자유형코드 를 빈값으로 설정
        // tmpSaveTp = ''
      }

      //계좌번호
      let tmpAcctNo = tmpRow.bfcer_accn_no // as-is : this.div_search.div_accn_no.Cb_accn_no.value

      //세제유형
      let tmpTaxTpGb = tmpRow.bfcer_taxsy_typ_sc_cd
      if(FSCommUtil.gfn_isNull(tmpTaxTpGb)){
        tmpTaxTpGb = ''
      }

      /***************************************************************************
       * 2010.06.14. LNH 
       * 환매구분 콤보의 유동적 변경으로 인하여 인덱스가 아닌 코드값으로 체크하도록 수정
       * e.postvalue  e.posttext
       * 11			전액환매
       * 12			좌수환매
       * 13			정액환매
       * 14			이자전액환매
       * 15			이자정액환매
       * 16			특정건좌수환매
         ****************************************************************************/
      let type = this.lv_cbo_redem_gb.value
      
      
      if (type == '11' || type == '14' || type == '16'){
        this.lv_ed_redem_req_qty.disabled = true // readonly(true)
      } else{
        this.lv_ed_redem_req_qty.disabled = false // readonly(false)
      }

      switch(type) {
        case '11':          
          this.lv_ed_redem_req_qty.value = this.ds_rp2510_1.paym_possamt
          this.lv_rdo_accn_rsc_apl_yn.value = 'N'
          this.lv_rdo_accn_rsc_apl_yn.disabled = false // enable(true)

          /** 
           * 임의식 계좌 전액환매 시 
           * 자동이체(CMS,자동대체,급여이체) 기등록된 경우 안내 팝업 
           * 2014.09.25 이지원 추가
           * 1. 전액환매  
           * 2. 임의식 계좌인 경우
           * 3. 퇴직신탁 계좌 아니고 환매 시 CMS 자동이체 체크 제외 (ASR200800042 - 연금계좌이체 보완(신연금, 당사 → 타사) 2차 개발 요청 (2020.09.22))
           */          
          if( tmpSaveTp === '11' && 
             (tmpAcctNo.substr(0, 7) != '1009176') && 
             (this.ds_rp2510_1.autoalt_yn === 'Y' || this.ds_rp2510_1.autoalt_yn === 'Y')){               
               this.fn_AlertPopup(0, {content: '자동이체 기등록된 계좌입니다. 추가해지여부를 확인해 주세요.'})
               return false
          }
          break        
        case '13': // 정액환매          
          if(Number(this.ds_rp2510_1.set_off_amt) > 0) //해외펀드 상계 금액 존재 시
          {
            this.fn_AlertPopup(0, {content: '해외펀드 손실상계 대상계좌로 금액 환매가 불가능합니다.\n 전액 또는 좌수환매를 해주시기 바랍니다.'})
            this.lv_cbo_redem_gb.value = ''
            return
          }
          break
        case '16': //특정건좌수환매 (사용안함)
          this.fn_SelectBuyList()
          break
        case '18': //매입건별(재투자분) (사용안함)
          this.fn_InitByPart('2510List', true)          
          this.lv_ed_redem_req_qty.value = this.ds_rp2510_2.value.orign_remn_qty
          this.lv_ed_redem_req_qty.disabled = false // readonly(false)
          break
        default:          
          break
      }
    },    
    /******************************************************************************
     * Function명 : fn_ChkCompAnnuAccnNo
     * 설명       : 확인 입력값 검증
     * 파라미터   : 계좌번호     
     * 리턴       : 환매 가능 여부
     ******************************************************************************/
    fn_ChkCompAnnuAccnNo(accnNo) {      
      console.log('fn_ChkCompAnnuAccnNo > ', accnNo)
      if (FSCommUtil.gfn_isNull(this.lv_commCode.code2020)) {
        console.log('lv_commCode.code2020 none > ', this.lv_commCode.code2020)
        return false
      }

      this.lv_commCode.code2020.forEach(item => {
        if (accnNo === item.value) {
          let msg = '관계사 연금계좌 ['
          msg += FSCommUtil.gfn_replace_accn_no(accnNo)
          msg += ']\n▶ 본사 확인 후 환매처리 가능합니다.'
          FSCommUtil.gfn_validate(this, msg)          
          return false
        }
      })
      return true
    },    
    /******************************************************************************
     * Function명 : fn_ChangeCboBtransAcctNo
     * 설명       : 약정계좌번호 변경 선택 시    
     ******************************************************************************/
    fn_ChangeCboBtransAcctNo(){
      if(this.ds_sy2000.length === 0){
        return
      }

      let btrans_acct_no = this.lv_cbo_btrans_acct_no.value

      if(!FSCommUtil.gfn_isNull(btrans_acct_no)){
        let tmpBankRow = this.ds_sy2000.filter(v=>v.bank_acct_no === btrans_acct_no)

        if(!FSCommUtil.gfn_isNull(tmpBankRow) && tmpBankRow.length > 0){
          this.lv_cbo_bank_cd.value = tmpBankRow[0].trd_bank_cd
          this.lv_ed_btrans_acct_no.value = tmpBankRow[0].bank_acct_no
          this.lv_ed_btrans_acct_nm.value = tmpBankRow[0].bank_acct_nm
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_ChangeBuyPlanAcctNo
     * 설명       : MMF매입계좌번호 변경 선택 시
     ******************************************************************************/
    fn_ChangeBuyPlanAcctNo(){
      if(this.ds_sy0000.length === 0){
        return
      }      
    },
    /******************************************************************************
     * Function명 : fn_ChangeRdoPaymRepayMth
     * 설명       : 출금지급방법선택 시
     * 파라미터   : 출금지급방법
     * as-is      : fn_rdo_paym_repay_mth_onitemchanged    
     ******************************************************************************/
    fn_ChangeRdoPaymRepayMth(pParam){
      // bfcer_taxsy_typ_sc_cd
      // if(FSCommUtil.gfn_isNull(this.lv_acct_list.value) || this.lv_acct_list.length === 0){
      //   return false
      // }      

      // 은행이체
      this.fn_InitByPart('bank_accn_no', false)
      // 약정계좌번호
      this.fn_InitByPart('btrans_accn_no', false)
      // MMF자동매입계좌번호
      this.fn_InitByPart('mmf_accn_no', false)
      // 예수금계좌
      this.fn_InitByPart('depo_acct_no', false)

      let type = !FSCommUtil.gfn_isNull(pParam)? pParam : this.lv_rdo_paym_repay_mth.value // 출금지급방법

      switch(type) {
        case '21': // 은행이체
          this.fn_InitByPart('bank_accn_no', true)
          break
        case '26': // 약정이체
          this.fn_InitByPart('btrans_accn_no', true)

          // 목록조회
          if(this.ds_sy2000.length === 0){
            this.fn_SearchBtransAccnNo()
          }
          break
        case '31': // MMF 자동매입
          this.fn_InitByPart('mmf_accn_no', true)

          // 목록조회
          if(this.ds_sy0000.length === 0){
            this.fn_SearchMmfAccnNo()
          }
          break
        case this.DEPO_ACCT_NO: // 예수금(브릿지계좌)
          this.fn_InitByPart('depo_acct_no', true)

          /** 
           * this.div_search.div_accn_no.setBridge_aply_cd("Y");	//	 브릿지 계좌만 조회
           * var bfcer_accn_no = this.div_search.div_accn_no.Cb_accn_no.value;
		         var findRow = this.div_search.div_accn_no.ds_acct_no.findRow("bfcer_accn_no", bfcer_accn_no);
		         var bfcer_apamt_accn_no = this.div_search.div_accn_no.ds_acct_no.getColumn(findRow, "bfcer_apamt_accn_no");
		         this.div_detail.div_left.mae_depo_acct_no.set_value(bfcer_apamt_accn_no);
          */          
          let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
          this.lv_ed_depo_acct_no.value = FSCommUtil.gfn_replace_accn_no(tmpRow.bfcer_apamt_accn_no)

          
          break
      }
    },
    /******************************************************************************
     * Function명 : fn_ChangeRdoFiGb
     * 설명       : 고객환매/FI환매 변경시
     ******************************************************************************/
    fn_ChangeRdoFiGb(){
      let tmpFiGb = this.lv_rdo_fi_gb.value
      if(tmpFiGb === 'FI'){
        this.lv_rdo_paym_repay_mth.disabled = true // readolny(true)
        this.lv_rdo_paym_repay_mth.value = this.DEPO_ACCT_NO
      } else {
        this.lv_rdo_paym_repay_mth.disabled = false // readolny(false)
        this.lv_rdo_paym_repay_mth.value = ''
      }

      this.fn_ChangeRdoPaymRepayMth()
    },
    /***************************************************************************************
    *	Function명 : fn_SelectRp2020_1
    *	설명			 : 매입목록 선택 (표에서)    
    * as-is func. : div_detail_div_bottom01_grd_rp_202000_01vo_oncellclick
    * 사용여부    : 사용안함
    *****************************************************************************************/
    fn_SelectDsRp2020_1(){
    },        
    /******************************************************************************
     * Function명 : fn_OnConfirm
     * 설명       : 확인 이벤트
     ******************************************************************************/
    fn_OnConfirm() {      
      if(!this.fn_ValidateConfirm()) {
        return
      }

      if(FSCommUtil.gfn_isNull(this.lv_rdo_agnt_chk_yn.value)){
        FSCommUtil.gfn_validate(this, '대리인여부을 선택하십시오.')
        return
      }

      // 유선구분 : 일반 고정
      this.fn_Confirm()

      // this.fn_ReportPrint()
    },
    /******************************************************************************
     * Function명 : fn_Confirm, fn_ConfirmResult, fn_ReportPrint
     * 설명       : 환매신청처리
     * eai        : F10570215
     * svcId      : insertRP202000_rpus
     ******************************************************************************/
    fn_Confirm() {     
      console.log('fn_Confirm...')      

      this.ds_rp2020_3 = {}

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570215_S'
      this.eaiCommObj.params = this.fn_GetParam('rp2020')
      console.log('fn_Confirm - params > ', this.eaiCommObj.params)

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_ConfirmResult)
    },
    fn_ConfirmResult(pResultData) {
      console.log('fn_ConfirmResult...')
      let t_data = pResultData.data
      let t_msg = FSMessageUtil.commMessage([])[t_data.error_msg]

      
      if( t_data.error_msg == '809905' ) { 
        
        this.ds_rp2020_3 = t_data.rp202000_O_03VO[0]

        if ( this.ds_rp2020_3.tm_bes_dl_yn === 'Y' ) {
          this.getStore('toast').dispatch('ADD', 'LT(Late-Trading)거래입니다. 고객님께 안내 바랍니다.')
        }

        let t_popupObj = {
          confirm: true,
          content: [
            t_msg
          ],
          confirmFunc: this.fn_ReportPrint,
          confirmData: {
            bfcer_cust_no: this.lv_ed_accn_no.value,            
          }
        }
        this.fn_AlertPopup(9, t_popupObj)

        this.fn_InitByPart('rpus')
        this.fn_InitByPart('info')
        this.fn_InitByPart('tax', false)
        this.fn_InitByPart('pay', false)
        this.fn_InitByPart('2020List', false)
        this.fn_InitByPart('2510List', false)
        this.fn_InitByPart('passv')
        
      } else {
        let t_valList = []
        let t_popupObj = {};
        t_popupObj.content = FSMessageUtil.commMessage(t_valList, t_data.error_msg)[t_data.error_msg.split('||')[0]]
        this.fn_AlertPopup(0, t_popupObj)
      }
    },
    /******************************************************************************
     * Function명 : fn_ConfirmPrint
     * 설명       : 확인서 출력
     * 사용여부   : 사용안함 (*CI 에서 전자서식 생성 및 PO 확인 후 사용가능)
     ******************************************************************************/
    async fn_ConfirmPrint(){
      console.log('fn_ConfirmPrint....')
      let tax_tp_gb = this.ds_rp2510_1.tax_tp_gb

      let docNm = ''
      if ( tax_tp_gb == "15" )
      {
        //소득공제 장기펀드 해지추징세액 확인서(소득자 보관용)
        //필요시 전자서식 생성하여 사용(80명 고객 존재)
        // docNm = "/crownix/RRP012020.mrd";	//RPT::R_RP2020_1.xml
      }
      else	
      {
        //장기주식형 해지추징세액 확인서 (사용안함)
        // docNm = "/crownix/RRP002020.mrd";	//RPT::R_RP2020.xml
      }

      let searchData = this.fn_GetParam('rp2020_11')
      console.log(searchData)

      let params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportRP202000_doc',
      }
      FSInfoUtil.commReportInfo(this, params, [docNm])
    },

    // fn_PrintTest() {
    //   let formList = []
    //   let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
    //   let tmpFundNm = tmpRow.text.split(' ')[1]
    //   if (tmpFundNm){
    //     console.log('tmpFundNm : ', tmpFundNm)
    //     // 전자서명동의서
    //     let FS000000 = {formId: 'FS000000'}
        
    //     let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
    //     let tmpFundNm = tmpRow.text.split(' ')[1]
    //     if(tmpFundNm) {
    //       FS000000.mappingData = {fund_nm: tmpFundNm}
    //     }
    //     formList.push(FS000000)
    //     FSInfoUtil.commReportInfo(this, formList)
    //   }
    // },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint () {
      console.log('fn_ReportPrint....')

      if (FSCommUtil.gfn_isNull(this.ds_rp2020_3)){
        this.fn_AlertPopup(0, {content:'환매신청 정보 없음'})
        return false
      }

      if (this.ds_rp2020_3.rprt_frm_no === '0109') {
        let tmpContentTitle = ''
        let tmpContent = []
        tmpContent.push('코스닥 벤처 소득공제 확인서 발급 계좌로')
        tmpContent.push('당사는 중도추징세를  원천징수하지 않고')
        tmpContent.push('‘출자지분 등 변경통지서’를 발급 합니다.')
        tmpContent.push('투자자가 직접 국세청 또는 원천징수의무자에게 신청하여야 합니다.')
        
        let t_popupObj = {
          confirm: false,          
          content: tmpContent,
          contentTitle: tmpContentTitle
        }

        this.fn_AlertPopup(7, t_popupObj)
      }

      // as-is parameter
      // reportParam=bfcer_cust_no=1001097_@frm_issnc_ymd=20230613_@issnc_hstr_sno=10_@tr_code=RP2035_@rprt_frm_no=0027_@bfcer_busn_trt_id=RP2035,
      let searchData = this.ds_rp2020_3
      searchData.bfcer_busn_trt_id = 'RP2020' // response 외에 화면 ID추가
      let formList = []      
      
      // 전자서명동의서
      // let FS000000 = {formId: 'FS000000'}
      
      let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      let tmpFundNm = tmpRow.text.split(' ')[1]
      // if(tmpFundNm) {
      //   FS000000.mappingData = {fund_nm: tmpFundNm}
      // }
      // formList.push(FS000000)

      this.$bizUtil.fsUtils.saveCustInfo(this, {fund_nm: tmpFundNm, saveTyp: 3})

      let FS000014 = {formId: 'FS000014'}
      FS000014.params = {
        reportMrdNm:'/crownix/RCO000027.mrd',
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportCO070500_Info',
      }
      formList.push(FS000014)
      
      if(!this.lv_rdo_pension_trans_yn.disabled) {
        let FS000022 = {formId: 'FS000022'}
        formList.push(FS000022)
      }

      // // 매입환매전환신청서
      // if (this.ds_rp2020_3.rprt_frm_no === '0027') {
      //   let FS000014 = {formId: 'FS000014'}       
      //   FS000014.params = {
      //     reportMrdNm:'/crownix/RCO000027.mrd',
      //     reportParam: JSON.stringify(searchData),
      //     reportUrl: 'reportCO070500_Info',
      //   }
      //   formList.push(FS000014)
      // }

      // // 연금 매입환매전환 신청서
      // if (this.ds_rp2020_3.rprt_frm_no === '0096') {
      //   let FS000022 = {formId: 'FS000022'}
      //   FS000022.params = {
      //     reportMrdNm:'/crownix/RCO000096.mrd',
      //     reportParam: JSON.stringify(searchData),
      //     reportUrl: 'reportCO070500_Info',
      //   }
      //   formList.push(FS000022)
      // }

      FSInfoUtil.commReportInfo(this, formList)

      /**
        * ASR230100171_LT 거래시 안내 문구 팝업 적용 (2023.01.26)
        * 환매 신청 시점에 LT거래 해당하는 경우 보고서 출력 후 LT거래 적용 메시지 출력        
        */

      // if (this.ds_rp2020_3.tm_bes_dl_yn === 'Y'){ // 시간외거래여부
      //   // this.fn_AlertPopup(0, {content: 'LT(Late-Trading)거래입니다. 고객님께 안내 바랍니다.'})
      //   FSCommUtil.gfn_validate(this, 'LT(Late-Trading)거래입니다. 고객님께 안내 바랍니다.')
      // }
    },
    /******************************************************************************
     * Function명 : fn_GetParam
     * 설명       : 파라미터 설정
     * 파라미터(type) : rp2020 (환매신청), rp2020_11 (확인서출력)
     ******************************************************************************/
    fn_GetParam(type) {
      let rtn = {}

      switch(type){
        case 'rp2020':
          //---------------------------------------------------------------
          // 은행계좌
          //---------------------------------------------------------------
          let tmpPaymRepayMth = this.lv_rdo_paym_repay_mth.value
          let tmpTrdBankCd = ''
          let tmpBankAccnNo = ''
          let tmpBankAcctNm = ''
          let tmpBankAccountId = ''
          let tmpCustNo = FSCommUtil.gfn_trim(this.lv_ed_accn_no.value).substr(0,7)
          let tmpBfcerRpusScCd = this.lv_cbo_redem_gb.value

          switch(tmpPaymRepayMth){
            case '21': // 은행이체
              tmpTrdBankCd = this.lv_cbo_bank_cd.value
              tmpBankAccnNo = this.lv_ed_btrans_acct_no.value
              tmpBankAcctNm = this.lv_ed_btrans_acct_nm.value
              break
            case '26': // 약정계좌
              tmpBankAccnNo = this.lv_cbo_btrans_acct_no.value
              let tmpBankRow = this.ds_sy2000.filter(v=>v.bank_acct_no === tmpBankAccnNo)
              if(!FSCommUtil.gfn_isNull(tmpBankRow) && tmpBankRow.length > 0){
                tmpTrdBankCd  = tmpBankRow[0].trd_bank_cd
                tmpBankAcctNm = tmpBankRow[0].bank_acct_nm
                tmpBankAccountId  = tmpBankRow[0].account_id
              }
              break
            case '31': // MMF계좌          
              tmpBankAccnNo = this.lv_cbo_buy_plan_acct_no.value
              break
            case this.DEPO_ACCT_NO: // 브릿지계좌          
              tmpBankAccnNo = this.lv_ed_depo_acct_no.value
              break        
          }

          //----------------------------------------------------------
          // 특정건선택
          //----------------------------------------------------------
          let tmpBuyStlYmd = '' // 특정건거래일자
          let tmpBuyDlNo = ''   // 특정건거래번호          
          if(tmpBfcerRpusScCd === '16' && this.ds_rp2020_1.value.length > 0){        
            let tmpBuyRow = this.ds_rp2020_1.value.filter(v=>v.chk_yn === '1')
            if(!FSCommUtil.gfn_isNull(tmpBuyRow)){
              tmpBuyStlYmd = tmpBuyRow[0].buy_stl_ymd
              tmpBuyDlNo  = tmpBuyRow[0].buy_stl_dl_no
            }
          } else if(tmpBfcerRpusScCd === '18' && this.ds_rp2510_2.length > 0){
            tmpBuyStlYmd = this.ds_rp2510_2[0].orign_trd_dt
            tmpBuyDlNo  = this.ds_rp2510_2[0].orign_trd_no
          }          

          //----------------------------------------------------------
          // 세금우대해지구분
          //----------------------------------------------------------
          // ASR161100945_연금이전(당사->타사) 자동화 업무개발 2017.01.03 by 문장혁
          /* 연금 타사이전인 경우 세금우대 해지 신청 구분 값 셋팅 변경 */
          let tmpTrmtCd = ''
          let tmpPensionTransYn = this.lv_rdo_pension_trans_yn.value          
          let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
          let tmpTaxsyTypSc = tmpRow.bfcer_taxsy_typ_sc_cd
          if(tmpTaxsyTypSc === '7' && tmpPensionTransYn === 'Y'){
            tmpTrmtCd = '26' // 이수관해지
          }else{
            tmpTrmtCd = '23' // 신청해지
          }
          rtn.tr_code = this.lv_trCode
          rtn.media = this.lv_media
          rtn.bfcer_accn_no = FSCommUtil.gfn_trim(tmpRow.bfcer_accn_no) // 계좌번호
          rtn.secure_passv  = this.lv_ed_secure_password.value    // 비밀번호
          rtn.bfcer_cust_no = tmpCustNo                           // 고객번호
          rtn.bfcer_rpus_sc_cd = tmpBfcerRpusScCd                 // 환매구분코드
          rtn.trmt_cd = tmpTrmtCd                                 // 세금우대 해지 신청 구분값
          rtn.accn_rsc_apl_yn = this.lv_rdo_accn_rsc_apl_yn.value // 계좌해지신청여부
          
          rtn.apply_dt = this.lv_cal_apply_dt.value.replace(/[^0-9]/g, "")     // 환매체결일
          rtn.rpus_rsrv_ymd = this.lv_cal_std_dt.value.replace(/[^0-9]/g, "")  // 환매예약일자
          rtn.redem_req_qty = this.lv_ed_redem_req_qty.value     // 신청좌수
          rtn.tel_use_yn = this.lv_rdo_ars_gb.value               // 유선여부
          rtn.buy_stl_ymd = tmpBuyStlYmd                          // 특정건거래일자
          rtn.buy_stl_dl_no = tmpBuyDlNo                          // 특정건거래번호
          rtn.paym_repay_mth = tmpPaymRepayMth                    // 출금지급방법
          rtn.pension_trans_yn = tmpPensionTransYn                // 연금환매구분
          rtn.trsf_bfcer_bnk_cd = tmpTrdBankCd                    // 이체은행코드
          rtn.bnk_accn_no = tmpBankAccnNo.replace(/[^0-9]/g, "")  // 은행계좌번호
          rtn.btrans_acct_nm = tmpBankAcctNm                      // 예금주명
          rtn.intrn_trsf_accn_id = tmpBankAccountId               // 인터넷이체계좌ID
          rtn.fnd_cust_nm = this.lv_ed_cust_nm.value              // 펀드고객명
          rtn.fi_gb = this.lv_rdo_fi_gb.value                     // 내부거래여부
          rtn.ars_pswn_yn = ''
          break
        case 'rp2020_11':
          rtn.cust_nm = this.ds_rp2510_1.cust_nm
          rtn.acct_no = this.ds_rp2510_1.acct_no
          rtn.setl_plan_dt = this.ds_rp2510_1.setl_plan_dt
          rtn.fund_cd = this.ds_rp2510_1.fund_cd
          rtn.fund_nm = this.ds_rp2510_1.fund_nm
          rtn.sum_year01 = this.ds_rp2510_1.sum_year01
          rtn.sum_year02 = this.ds_rp2510_1.sum_year02
          rtn.sum_year03 = this.ds_rp2510_1.sum_year03
          rtn.trmt_lev_incm_tax01 = this.ds_rp2510_1.trmt_lev_incm_tax01
          rtn.trmt_lev_resi_tax01 = this.ds_rp2510_1.trmt_lev_resi_tax01
          rtn.trmt_lev_tax01 = parseFloat(this.ds_rp2510_1.trmt_lev_incm_tax01) + parseFloat(this.ds_rp2510_1.trmt_lev_resi_tax01)
          rtn.trmt_lev_incm_tax02 = this.ds_rp2510_1.trmt_lev_incm_tax02
          rtn.trmt_lev_resi_tax02 = this.ds_rp2510_1.trmt_lev_resi_tax02
          rtn.trmt_lev_tax02 = parseFloat(this.ds_rp2510_1.trmt_lev_incm_tax02) + parseFloat(this.ds_rp2510_1.trmt_lev_resi_tax02)
          rtn.trmt_lev_incm_tax03 = this.ds_rp2510_1.trmt_lev_incm_tax03
          rtn.trmt_lev_resi_tax03 = this.ds_rp2510_1.trmt_lev_resi_tax03
          rtn.trmt_lev_tax03 = parseFloat(this.ds_rp2510_1.trmt_lev_incm_tax03) + parseFloat(this.ds_rp2510_1.trmt_lev_incm_tax03)
          rtn.levy_amt = this.ds_rp2510_1.levy_amt
          rtn.levy_total_tax = parseFloat(this.ds_rp2510_1.levy_inc_tax) + parseFloat(this.ds_rp2510_1.levy_resi_tax)
          break
      }
      
      return rtn
    },

    /******************************************************************************
     * Function명 : fn_ArsResult 
     * 설명       : ARS 비밀번호 체크 종료 후 호출되는 CallBack 성 메소드이며, ARS 처리결과를 저장한다.
     * as-is func. : cbArsRltComplete
     * 사용여부 : 사용안함
     ******************************************************************************/
    fn_ArsResult() {

    },

    /******************************************************************************
     * Function명 : fn_RpusAplSearch, fn_RpusAplSearchResult
     * 설명       : 인출가능금액조회 (유선인경우 호출)
     * 사용여부   : 사용안함
     ******************************************************************************/
    fn_RpusAplSearch() {
      console.log("fn_RpusAplSearch.....");
    },

    /******************************************************************************
     * Function명 : fn_SelectBuyList
     * 설명       : 특정건좌수환매선택시 매입목록조회
     * 사용여부   : 사용안함
     * svcID     : selectRP202000_buyList
     ******************************************************************************/
    fn_SelectBuyList() {
      console.log('fn_AgntListSearch...')
      let tmpAccnNo = FSCommUtil.gfn_trim(this.lv_ed_accn_no.value).replace(/[^0-9]/g, "")

      this.ds_rp2020_1.value = []

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570213_S'
      this.eaiCommObj.params = {
        tr_code : this.lv_trCode,
        media : this.lv_media,
        bfcer_cust_no  : this.lv_ed_cust_no.value,  
        bfcer_accn_no  : tmpAccnNo
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SelectBuyListResult)
    },
    fn_SelectBuyListResult (pResultData) {
        console.log('fn_SelectBuyListResult...')
        let t_data = pResultData.data.cu100000_O_01VO
        console.log(t_data)

        if (t_data.length > 0) {
        }
    },
    /******************************************************************************
     * Function명 : fn_SearchBtransAccnNo
     * 설명       : 약정계좌번호목록조회
     * svcId      : selectSY200000_AccnList   
     ******************************************************************************/
    fn_SearchBtransAccnNo() {
      console.log('fn_SearchBtransAccnNo...')
      let tmpCustNo = FSCommUtil.gfn_trim(this.lv_ed_accn_no.value).substr(0,7)

      this.ds_sy2000 = []
      this.lv_cbo_btrans_acct_no_items = []

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570202_S'
      this.eaiCommObj.params = {
        proc_gb: 'S',
        tr_code: this.lv_trCode,
        bfcer_cust_no  : tmpCustNo,        
        bp_search_auth_yn: 'N'
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchBtransAccnNoResult)
    },
    fn_SearchBtransAccnNoResult (pResultData) {
        console.log('fn_SearchBtransAccnNoResult...')

        let t_data = pResultData.data.sy200000_O_00VO
        console.log(t_data)

        if (t_data.length > 0) {
          this.ds_sy2000 = t_data
          let index = 0
          this.ds_sy2000.forEach((item,idx)=>{            
            //약정계좌번호 마스킹
            this.lv_cbo_btrans_acct_no_items.push({value: item.bank_acct_no, text: item.btrans_acct_no.replaceAll(item.bank_acct_no, this.fn_AccnNoMask(item.bank_acct_no))})
          })
          this.lv_cbo_btrans_acct_no.value = this.lv_cbo_btrans_acct_no_items[0].value // 첫번째 행 선택
          this.fn_ChangeCboBtransAcctNo()
        } else {
          this.fn_AlertPopup(0, {content: '등록된 약정계좌가 없습니다'})
        }
    },

    /******************************************************************************
     * Function명 : fn_SearchMmfAccnNo
     * 설명       : MMF매입계좌번호목록조회
     * svcId      : selectSY000000_AcctNoList
     ******************************************************************************/
    fn_SearchMmfAccnNo() {
      console.log('fn_SearchMmfAccnNo...')
      let tmpBfcerCstgrNo = FSCommUtil.gfn_trim(this.lv_ed_accn_no.value).replace(/[^0-9]/g, "")
      let tmpInputCheck = '3'
      let tmpIntTrYn = 'Y'
      let tmpBfcerAccnStatCd = 'Y'
      let tmpBridgeAcctYn = 'N'

      this.ds_sy0000 = []
      this.lv_cbo_buy_plan_acct_no_items = []      

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570208_S'
      this.eaiCommObj.params = {        
        bfcer_cstgr_no  : tmpBfcerCstgrNo,                // 수익증권고객그룹번호
        input_check     : tmpInputCheck,                  // 0.전체,1.예수금,2.예수금제외, 3.MMF계좌, 4.MMF예수금제외, 5.MMF+예수금
        int_tr_yn       : tmpIntTrYn,                     // 인터넷뱅킹계좌포함여부
        bfcer_accn_stat_cd  : tmpBfcerAccnStatCd,         // 계좌상태 : 'Y' 정상, 'N'전체
        bridge_acct_yn: tmpBridgeAcctYn                   // 브릿지 계좌 여부 : Y - 신규개설 or 계좌 통합
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchMmfAccnNoResult)
    },
    fn_SearchMmfAccnNoResult (pResultData) {
        console.log('fn_SearchMmfAccnNoResult...')
        let t_data = pResultData.data.sy000000_O_00VO
        let lv_Vm = this
        console.log(t_data)

        if (t_data.length > 0) {
          let firstVal = ''          
          lv_Vm.ds_sy0000 = t_data          
          lv_Vm.ds_sy0000.forEach((item,idx)=>{            
            if (idx === 0 ){              
              firstVal = item.bfcer_accn_no
            }
            lv_Vm.lv_cbo_buy_plan_acct_no_items.push({value: item.bfcer_accn_no, text: item.acct_no_nm})
          })          
          lv_Vm.lv_cbo_buy_plan_acct_no.value = firstVal // 첫번째 행 선택
        } else {
          lv_Vm.fn_AlertPopup(0, {content: '해당통장에 MMF계좌가 없습니다.'})

          //예수금계좌 설정
          lv_Vm.lv_rdo_paym_repay_mth.value = lv_Vm.DEPO_ACCT_NO
          lv_Vm.fn_ChangeRdoPaymRepayMth()          
        }
    },
    /******************************************************************************
     * Function명 : fn_AccnNoMask
     * 설명       : 계좌번호 마스킹
     ******************************************************************************/
    fn_AccnNoMask(accn_no) {
      if(accn_no.length >= 9){      // 연계계좌번호 9자리 이상인 경우
        accn_no = accn_no.substr(0,3) + '*****' + accn_no.substr(8)
      }
      if(accn_no.length < 9){       // 연계계좌번호 9자리 미만인 경우
        accn_no = accn_no.substr(0,3) + '*'.repeat(accn_no.length - 3)
      }
      return accn_no
    }
  },
};
</script>
<style scoped>
</style>
